
import { defineComponent, computed, PropType } from 'vue';
import { Price } from '../classes/Models';
import Enumerable from 'linq';
import PriceTableRowComponent from './PriceTableRowComponent.vue'
import Money from '@/classes/Money';
import Country from '@/classes/Country';

export default defineComponent({
  name: 'PricesTableComponent',
  components: {
    price : PriceTableRowComponent
  },
  props: {
    country: String,
    prices: Array as PropType<Array<Price>>,
    isbn: String
  },

  setup(props, { emit }) {
    const suppliersWithStock = computed(() => Enumerable.from<Price>(props.prices).where(price => {
      return price.price && price.price > 0;
    }));

    const suppliersWithNoStock = computed(() => 
      Enumerable.from<Price>(props.prices)
        .where(price => !price.price)
        .orderBy(supplier => supplier.supplier)
        .select(supplier => supplier.supplier)
        .toArray().join(", "));
    
    const sortedSuppliers = computed(() => suppliersWithStock.value.orderBy(price => {
      let total = price.price;
      if (price.postage) total += price.postage;
      return total && total > 0 ? total : Infinity;
    }).toArray());

    const visibility = {
      "price": "d-none d-lg-table-cell",
      "availability": "d-none d-md-table-cell",
      "postage": "d-none d-lg-table-cell"
    }

    const available = computed(() => sortedSuppliers.value.length);
    const lowest = computed(() => Money.getTotal(sortedSuppliers.value[0]));
    const highest = computed(() => Money.getTotal(sortedSuppliers.value[sortedSuppliers.value.length -1]));
    const currency = computed(() => Country.valueOf(props.country).currency);

    return { suppliersWithStock, suppliersWithNoStock, sortedSuppliers, available, lowest, highest, currency, emit, visibility }
  },
});
