export default class Country {

  public static countries: Map<string, Country> = new Map();

  public static AU = new Country("au", "Australia", "en-AU", "AUD");
  public static CA = new Country("ca", "Canada", "en-CA", "CAD");
  public static CN = new Country("cn", "China", "zh-CN", "CNY");
  public static IE = new Country("ie", "Ireland", "en-IE", "EUR");
  public static HK = new Country("hk", "Hong Kong", "en-HK", "HKD");
  public static JP = new Country("jp", "Japan", "ja-JP", "JPY");
  public static NZ = new Country("nz", "New Zealand", "en-NZ", "NZD");
  public static SG = new Country("sg", "Singapore", "en-SG", "SGD");
  public static TW = new Country("tw", "Taiwan", "en-TW", "TWD");
  public static UK = new Country("uk", "United Kingdom",  "en-GB", "GBP", "gb");
  public static US = new Country("us", "USA",  "en-US", "USD");
  
  public code: string;
  public name: string;
  public locale: string;
  public currency: string;
  public flag: string;

  public static valueOf(code: string): Country {
    return this.countries.get(code);
  }

  constructor(code: string, name: string, locale: string, currency: string, flag?: string) {
    this.code = code;
    this.name = name;
    this.locale = locale;
    this.currency = currency;
    this.flag = flag ?? code;

    Country.countries.set(code, this);
  }
}