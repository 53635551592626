<template>
  <div>
    <h1 class="display-6 text-center pb-3">{{ title }}</h1>
     <div class="row">
      <book v-for="book in getBooks" :prices="getPrices(book.isbn)" :key="book.isbn" :book="book" :country="country" class="mb-4 col-8 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3"></book>
    </div>
    
    <div v-if="showLoadMore" class="gap-3 d-sm-flex justify-content-sm-center mb-4 text-center">
      <button type="button" class="btn btn-secondary" @click="loadMore">Load more...</button>
    </div>

  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useGrid } from 'vue-screen'
import BookComponent from '@/components/BookComponent.vue'
import { Book, Price } from '@/classes/Models';
import Enumerable from 'linq';

export default defineComponent({
  name: 'ShelfComponent',
  components: {
    book : BookComponent,
  },
  props: {
    title: String,
    country: String,
    books: Map as PropType<Map<string, Book>>,
    prices: Map as PropType<Map<string, Array<Price>>>
  },
  setup(props) {
    const screen = useGrid("bootstrap");
    const lines = ref(2);
    const itemsToDisplay = computed(() => {
      const length = props.books?.size || 0;
      let itemsPerRow = 3;
      switch(screen.breakpoint) {
        case 'xxl':
        case 'xl':
          itemsPerRow = 8; break;
        case 'lg':
        case 'md':
          itemsPerRow = 6; break;
        case 'sm':
        case 'xs':
          itemsPerRow = 3; break;
      }

      const items = itemsPerRow * lines.value;
      return (items < length) ? items : length;
    });

    const getBooks = computed(() => Enumerable.from(props.books?.values()).toArray().splice(0, itemsToDisplay.value));
    const showLoadMore = computed(() => itemsToDisplay.value < props.books.size);
    
    function getPrices(isbn: string) {
      return props.prices?.get(isbn);
    }

    function loadMore() {
      lines.value = lines.value + 2;
    }

    return { screen, getBooks, getPrices, showLoadMore, loadMore }
  }
});
</script>

<style scoped>
</style>