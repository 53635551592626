
import { defineComponent, computed } from 'vue';
import { useStore } from '../store/Store'
import PricesTableComponent from "../components/PriceTableComponent.vue"
import { useHead } from '@vueuse/head';
import Head from '../classes/Head';
import PriceUpdateProgressComponent from '@/components/PriceUpdateProgressComponent.vue';
import BuyButtonComponent from '@/components/BuyButtonComponent.vue';
import Enumerable from 'linq';
import { useGrid } from 'vue-screen';

export default defineComponent({
  name: 'BookView',
  components: {
    prices : PricesTableComponent,
    priceUpdateProgress: PriceUpdateProgressComponent,
    buyButton: BuyButtonComponent
  },
  setup() {
    const store = useStore();

    const country = computed(() => store.state.country);
    const isbn = computed(() => store.state.book.isbn);

    const book = computed(() => store.state.book.books.get(isbn.value));
    const authors = computed(() => book.value ? book.value.authors?.join(", ") : "");

    const cover = computed(() => book.value ? `https://cover.librarist.com/${book.value.isbn}.jpg` : null);

    const prices = computed(() => Enumerable.from(store.state.price.prices.get(isbn.value)).orderBy(price => {
      let total = price.price;
      if (price.postage) total += price.postage;
      return total && total > 0 ? total : Infinity;
    }).toArray());

    const totalSuppliers = computed(() => prices.value?.length);
    const updatingSuppliers = computed(() => prices.value?.filter(price => price.updating).length);
    const percentUpdated = computed(() => {
      if (totalSuppliers.value && updatingSuppliers.value) {
        return Math.round((totalSuppliers.value - updatingSuppliers.value) / totalSuppliers.value * 100);
      }
      return 100;
    });

    const emptyPrices = computed(() => {
      if (prices.value) return prices.value.map(price => {
        const p = price;
        p.price = null;
        return p;
      });
    });

    const screen = useGrid("bootstrap");
    const readMoreCharacters = computed(()=> {
      switch(screen.breakpoint) {
        case 'xxl':
          return 1150;
        case 'xl':
          return 840;
        case 'lg':
          return 520;
        default:
          return 500;
      }
    });

    function updatePrices() {
      store.dispatch("price/update", {
          country: store.state.country.code,
          isbns: book.value.isbn
      });
    }

    function updateBook() {
      store.dispatch("book/fetchBooks", {
          country: store.state.country,
          isbns: book.value.isbn
      });
    }

    store.dispatch("book/load", {country: country.value.code, isbns: isbn.value});

    useHead(computed(() => Head.book(country.value.code, book.value, prices.value)));

    return { isbn, book, authors, cover, prices, country, percentUpdated, emptyPrices, readMoreCharacters, updatePrices, updateBook }
  }
});
