
import { ref, defineComponent, onMounted, computed } from 'vue';
import { useStore } from './store/Store';
import { UrlParser } from './url-parser';
import HomeView from './views/HomeView.vue';
import BookView from './views/BookView.vue';
import SearchView from './views/SearchView.vue';
import PageNotFoundView from './views/PageNotFoundView.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import DebugComponentVue from './components/DebugComponent.vue';
import Page from './classes/Page';

export default defineComponent({
  name: 'Librarist',
  components: {
    navbar : NavbarComponent,
    debug: DebugComponentVue,

    BookView, HomeView, SearchView, PageNotFoundView
  },
  setup() {
    const store = useStore();
    
    const view = ref(null);
    const component = computed(() => `${view.value}View`);
    const showSearchInNavbar = computed(() => view.value != "Search" && view.value != "Home");
    const debug = false;

    onMounted(() => {
      const parser = new UrlParser();

      if (parser.country && parser.page) {
        switch(parser.page) {
          case Page.HOME:
            store.dispatch(`init`, { country: parser.country });
            view.value = "Home";
            return;
          case Page.BOOK:
            store.dispatch(`book/init`, { country: parser.country, isbn: parser.isbn });
            view.value = "Book";
            return;
          case Page.SEARCH:
            store.dispatch(`search/init`, { country: parser.country, searchQuery: parser.search });
            view.value = "Search";
            return;
        }
      }

      if (parser.page == Page.REDIRECT) {
        window.location.href = '/nz';
        return;
      }

      store.dispatch(`notfound/init`, { country: parser.country, isbn: parser.isbn, searchQuery: parser.search });
      view.value = "PageNotFound";
    });

    return { view, component, showSearchInNavbar, debug }
  }
});
