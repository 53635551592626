
import { defineComponent, computed } from 'vue';
import { useHead } from '@vueuse/head';
import Head from '../classes/Head';

export default defineComponent({
  name: 'BookView',
  components: {
  },
  setup() {
    useHead(computed(() => Head.notFound()));

    return {  }
  }
});
