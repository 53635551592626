import { Module } from 'vuex';
import { db } from "../db";
import axios from 'axios'
import { Book } from '@/classes/Models';
import { RootState } from './Store';

export interface SearchState {
  inProgress: boolean;
  query: string;
}

const searchModule: Module<SearchState, RootState> = {
  namespaced: true,
  mutations: {
    setQuery: async(state, query) => {
      state.query = query;
    },
    setProgress: async(state, progress) => {
      state.inProgress = progress;
    }
  },
  actions: {
    search: async ({ dispatch, commit }, { country, query }) => {
      commit("book/clear", {}, { root: true });
      commit("setProgress", true);

      axios.get(process.env.VUE_APP_LIBRARIST_API + `${country}/search?q=${query}`)
        .then((response) => {
          // const isbns: Array<string> = [...response.data.slice(0, 10)];
          const isbns: Array<string> = [...response.data.slice(0, 30)];
          // isbns.push(...response.data.);

          // console.log(isbns);
          for (let i = 0; i < isbns.length; i += 10) {
            const collection = db.collection("books");

            let end = i + 10;
            if (end > isbns.length) end = isbns.length;
            const chunk: Set<string> = new Set(isbns.slice(i, end));

            let update = false;
            collection.where("isbn", "in", [...chunk]).onSnapshot((snapshot) => {
              if (snapshot.metadata.fromCache) {
                // console.log("skipping search snapshot from cache: " + snapshot.size);
                return;
              }
              let books: Array<Book> = [];
              snapshot.forEach(snap => {
                const book = Object.assign(new Book, snap.data());
                books.push(book);
                chunk.delete(book.isbn);
              });

              if (update) {
                // in case of update, propagate only changed books
                const changes: Set<string> = new Set();
                snapshot.docChanges().forEach(change => changes.add(change.doc.data().isbn));
                books = books.filter(book => changes.has(book.isbn));
              } else {
                // in case of first load, add missing books
                chunk.forEach(isbn => books.push(new Book(isbn)));
              }

              update = true;

              dispatch("book/setBooksAndUpdatePrices", { country: country, books: books }, { root: true });
            });
          }
        })
        .finally(() => commit("setProgress", false));
    },
    init: async ({ commit }, { country, searchQuery }) => {
      commit("updateContext", { country: country }, { root: true });
      commit("setQuery", searchQuery );
    },
  },
}

export default searchModule;
