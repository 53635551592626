
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'PriceButtonComponent',
  props: {
    price: String,
    link: String,
    updating: Boolean
  },
  setup(props) {
    const href = computed(() => props.link ?? "#");
    return { href }
  },
});
