<template>
  <div v-if="book" itemprop="mainEntity" itemscope itemtype="https://schema.org/Product">
    <div class="row">
      <h3 class="text-center">
        <span itemprop="name">{{ book.title }}</span>
        <span class="px-2 d-none d-md-inline divider">&ndash;</span>
        <span itemprop="author" class="author d-none d-md-inline">{{ authors }}</span>
      </h3>
    </div>

    <price-update-progress :percentage="percentUpdated"></price-update-progress>

    <div class="row pt-3">

      <div class="col-12 col-mdd-7 col-lg-5 col-xl-5">
        <img v:v-if="cover" :src="cover" itemprop="image" class="img-fluid mx-auto" alt="..." >
      </div>

      <div class="col-12 col-mdd-8 col-lg-8 col-xl-7 order-lg-3">
        <buy-button :country="country.code" :isbn="isbn" :prices="prices" :percentage="percentUpdated"  class="d-none d-sm-block pb-4"></buy-button>

        <div class="pb-2 d-md-none"><strong>{{ authors }}</strong></div>
        <strong>ISBN</strong>: <span itemprop="isbn">{{ book.isbn }}</span>

        <div v-if="book.pages">
          <strong>Pages</strong>: <span itemprop="numberOfPages">{{ book.pages }}</span>
        </div>

        <div v-if="book.format">
          <strong>Format</strong>: <span itemprop="bookFormat">{{ book.format }}</span>
        </div>
      </div>

      <buy-button :country="country.code" :isbn="isbn" :prices="prices" :percentage="percentUpdated" class="pt-4 d-sm-none"></buy-button>

      <div class="col pt-3 pt-lg-0">
        <read-more v-if="book.description" more-str="+ Show more" :text="book.description" link="#" less-str="- Show less" :max-chars="readMoreCharacters"></read-more>
      </div>

    </div>

    <div class="container">

      <a v-show="false" href="#" @click="updateBook">Update book</a>
      
      <prices :country="country.code" :isbn="book.isbn" :prices="prices" @update="updatePrices" class="mt-3"></prices>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '../store/Store'
import PricesTableComponent from "../components/PriceTableComponent.vue"
import { useHead } from '@vueuse/head';
import Head from '../classes/Head';
import PriceUpdateProgressComponent from '@/components/PriceUpdateProgressComponent.vue';
import BuyButtonComponent from '@/components/BuyButtonComponent.vue';
import Enumerable from 'linq';
import { useGrid } from 'vue-screen';

export default defineComponent({
  name: 'BookView',
  components: {
    prices : PricesTableComponent,
    priceUpdateProgress: PriceUpdateProgressComponent,
    buyButton: BuyButtonComponent
  },
  setup() {
    const store = useStore();

    const country = computed(() => store.state.country);
    const isbn = computed(() => store.state.book.isbn);

    const book = computed(() => store.state.book.books.get(isbn.value));
    const authors = computed(() => book.value ? book.value.authors?.join(", ") : "");

    const cover = computed(() => book.value ? `https://cover.librarist.com/${book.value.isbn}.jpg` : null);

    const prices = computed(() => Enumerable.from(store.state.price.prices.get(isbn.value)).orderBy(price => {
      let total = price.price;
      if (price.postage) total += price.postage;
      return total && total > 0 ? total : Infinity;
    }).toArray());

    const totalSuppliers = computed(() => prices.value?.length);
    const updatingSuppliers = computed(() => prices.value?.filter(price => price.updating).length);
    const percentUpdated = computed(() => {
      if (totalSuppliers.value && updatingSuppliers.value) {
        return Math.round((totalSuppliers.value - updatingSuppliers.value) / totalSuppliers.value * 100);
      }
      return 100;
    });

    const emptyPrices = computed(() => {
      if (prices.value) return prices.value.map(price => {
        const p = price;
        p.price = null;
        return p;
      });
    });

    const screen = useGrid("bootstrap");
    const readMoreCharacters = computed(()=> {
      switch(screen.breakpoint) {
        case 'xxl':
          return 1150;
        case 'xl':
          return 840;
        case 'lg':
          return 520;
        default:
          return 500;
      }
    });

    function updatePrices() {
      store.dispatch("price/update", {
          country: store.state.country.code,
          isbns: book.value.isbn
      });
    }

    function updateBook() {
      store.dispatch("book/fetchBooks", {
          country: store.state.country,
          isbns: book.value.isbn
      });
    }

    store.dispatch("book/load", {country: country.value.code, isbns: isbn.value});

    useHead(computed(() => Head.book(country.value.code, book.value, prices.value)));

    return { isbn, book, authors, cover, prices, country, percentUpdated, emptyPrices, readMoreCharacters, updatePrices, updateBook }
  }
});
</script>

<style scoped>
.divider, .author {
  font-weight: 400;
}
</style>