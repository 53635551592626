import firebase from 'firebase';

export class Context {
  constructor(view?: string, country?: string, isbn?: string) {
    this.view = view;
    this.country = country;
    this.isbn = isbn;
  }
  view: string | undefined;
  country: string | undefined;
  isbn: string | undefined;
}

export class Price {
  country: string
  supplier: string
  isbn: string
  price: number
  postage: number
  delivery: string
  updating: boolean
  timestamp: firebase.firestore.Timestamp

  public isOutdated(): boolean {
    const ago = new Date().getTime() - 24*60*60*1000;
    return this.timestamp.toDate().getTime() < ago;
  }
}
 
export class Book {
  constructor(isbn: string = null) {
    this.isbn = isbn;
  }
  isbn: string
  title: string
  authors: Array<string>
  format: string
  pages: number
  description: string
  updating: boolean
  timestamp: firebase.firestore.Timestamp

  public needsUpdate(): boolean {
    if (this.timestamp === null || this.updating) return false;

    if (this.timestamp === undefined) return true;

    const ago = new Date().getTime() - 7*24*60*60*1000;
    if (this.timestamp.toDate().getTime() < ago) return true;

    return false;
  }

  public getAuthor() {
    return this.authors && this.authors.length > 0 ? this.authors[0] : "";
  }
}

export class Books {
  public books: Array<string>;
  public status: string;

  constructor() {
    this.books = [];
  }
}
  