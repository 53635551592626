<template>
  <div>
    <a :href="href" class="btn btn-primary btn-sm stretched-link" role="button" aria-disabled="true">
      <font-awesome-icon v-if="!updating" icon="shopping-cart" />
      <font-awesome-icon v-if="updating" icon="sync-alt" spin />
      {{ price }}
    </a>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'PriceButtonComponent',
  props: {
    price: String,
    link: String,
    updating: Boolean
  },
  setup(props) {
    const href = computed(() => props.link ?? "#");
    return { href }
  },
});
</script>

<style lang="scss" scoped>
a.btn svg {
  margin-right: 0.25em;
}
</style>