<template>
  <div class="Prices">
    <a v-show="false" href="#" @click.prevent="emit('update')">Update prices</a>
    
    <table class="table">
      <caption v-if="suppliersWithNoStock">
        <div v-if="available > 1" itemprop="offers" itemscope itemtype="https://schema.org/AggregateOffer">
          Available at <span itemprop="offerCount">{{ available }}</span> suppliers (<span itemprop="lowPrice">{{ lowest }}</span> - <span itemprop="highPrice">{{ highest }}</span>). 
          All prices are in <span itemProp="priceCurrency" :content="currency">{{ currency }}</span>.
        </div>
        Not available: {{ suppliersWithNoStock }}</caption>
      <thead v-if="sortedSuppliers.length > 0">
        <tr>
          <th scope="col">Supplier</th>
          <th :class="visibility.availability" scope="col">Availability</th>
          <th :class="visibility.price" scope="col"><div class="text-center">Price</div></th>
          <th :class="visibility.postage" scope="col"><div class="text-center">Postage</div></th>
          <th scope="col"><div class="text-center">Total</div></th>
        </tr>
      </thead>
      <tbody>
        <price v-for="price in sortedSuppliers" :country="country" :isbn="isbn" :key="price.supplier" :price="price" :visibility="visibility"></price>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { Price } from '../classes/Models';
import Enumerable from 'linq';
import PriceTableRowComponent from './PriceTableRowComponent.vue'
import Money from '@/classes/Money';
import Country from '@/classes/Country';

export default defineComponent({
  name: 'PricesTableComponent',
  components: {
    price : PriceTableRowComponent
  },
  props: {
    country: String,
    prices: Array as PropType<Array<Price>>,
    isbn: String
  },

  setup(props, { emit }) {
    const suppliersWithStock = computed(() => Enumerable.from<Price>(props.prices).where(price => {
      return price.price && price.price > 0;
    }));

    const suppliersWithNoStock = computed(() => 
      Enumerable.from<Price>(props.prices)
        .where(price => !price.price)
        .orderBy(supplier => supplier.supplier)
        .select(supplier => supplier.supplier)
        .toArray().join(", "));
    
    const sortedSuppliers = computed(() => suppliersWithStock.value.orderBy(price => {
      let total = price.price;
      if (price.postage) total += price.postage;
      return total && total > 0 ? total : Infinity;
    }).toArray());

    const visibility = {
      "price": "d-none d-lg-table-cell",
      "availability": "d-none d-md-table-cell",
      "postage": "d-none d-lg-table-cell"
    }

    const available = computed(() => sortedSuppliers.value.length);
    const lowest = computed(() => Money.getTotal(sortedSuppliers.value[0]));
    const highest = computed(() => Money.getTotal(sortedSuppliers.value[sortedSuppliers.value.length -1]));
    const currency = computed(() => Country.valueOf(props.country).currency);

    return { suppliersWithStock, suppliersWithNoStock, sortedSuppliers, available, lowest, highest, currency, emit, visibility }
  },
});
</script>

<style lang="scss" scoped>
</style>