import { Module } from 'vuex';
import { RootState } from './Store';

export interface NotFoundState {
  isbn: string;
}

const NotFoundModule: Module<NotFoundState, RootState> = {
  namespaced: true,
  state: {} as NotFoundState,
  mutations: {
  },
  actions: {
    init: async ({ commit }, { country, isbn }) => {
      commit("updateContext", { country: country }, { root: true });
    },
  },
}

export default NotFoundModule;
