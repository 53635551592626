
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '../store/Store'
import SearchComponent from '@/components/SearchComponent.vue';
import ShelfComponent from '@/components/ShelfComponent.vue';
import { useHead } from '@vueuse/head';
import Head from '../classes/Head';

export default defineComponent({
  name: 'SearchView',
  components: {
    shelf : ShelfComponent,
    search: SearchComponent
  },
  setup() {
    const store = useStore();
    const books = computed(() => store.state.book.books);
    const prices = computed(() => store.state.price.prices);
    const country = computed(() => store.state.country);
    const query = ref(store.state.search.query);
    
    const shelfTitle = computed(() => {
      if (!query.value || query.value.length == 0) return `Can't search for empty text`;
      if (store.state.search.inProgress) return `Searching for '${query.value}' - won't be long!`;
      if (books.value?.entries.length == 0) `Sorry, nothing found for '${query.value}'`;
      return `Search results for '${query.value}'`;
    });

    if (query.value?.length > 0) {
      store.dispatch("search/search", {country: country.value.code, query: query.value });
    }

    useHead(computed(() => Head.search(country.value.code, query.value)));

    return { screen, country, query, books, prices, shelfTitle }
  }
});
