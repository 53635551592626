import { Book, Price } from "./Models";

class Head {
    public title: string;
}

export default class HeadGenerator {

    public static book(country: string, book: Book, prices: Iterable<Price>) {
        const head = new Head();
        
        const title = book?.title;
        const author = book?.getAuthor();

        if (title) {
            head.title = title;
            if (author) head.title += ` - ${author}`;
            head.title += ` (Librarist ${country.toUpperCase()})`;
        }
        
        return head;
    }

    public static page(country: string, title: string) {
        const head = new Head();
        
        // head.title = `Search results for '${query}'`
        // head.title += ` (Librarist ${country.toUpperCase()})`;
        
        return head;
    }

    public static search(country: string, query: string) {
        const head = new Head();
        
        head.title = `Search results for '${query}'`
        head.title += ` (Librarist ${country.toUpperCase()})`;
        
        return head;
    }

    public static notFound() {
        const head = new Head();
        
        head.title = `Page Not Found (Librarist)`
        
        return head;
    }

}