<template>
  <div class="container search bg-dark text-secondary pt-4 pb-4 text-center mb-4">
    <h1 class="display-5 fw-bold text-white">Librarist</h1>
    <div class="mx-auto">
      <p class="fs-5 mb-4">Find the best book prices on the internet</p>
    </div>
    <div class="d-flex justify-content-center">
      <form :action="`/${country.code}/search`" class="col-22 col-sm-18 col-lg-14">
        <input name="q" v-model="query" type="text" class="form-control text-center mb-3" placeholder="Search for title, author, isbn or any keyword" aria-label="Search">
        <input type="submit" class="btn btn-outline-light btn-md px-4" value="Search">
      </form>
    </div>      
  </div>
</template>

<script lang="ts">
import { useStore } from '@/store/Store';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'SearchComponent',
  setup() {
    const store = useStore();
    const country = computed(() => store.state.country )
    const query = ref(computed(() => store.state.search.query ).value);
    
    return { query, country }
  },
});
</script>

<style lang="scss" scoped>
</style>