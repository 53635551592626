<template>
  <div>
    <pre>{{ state.search }}</pre>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from 'vue';
import { useStore } from '../store/Store'

export default defineComponent({
  name: 'DebugComponent',
  components: {
  },
  setup() {
    const store = useStore();
    const state = computed(() => store.state);

    // const s = computed(() => ...mapState('module/search', 'search'));

  /*watch(state.value, (before, after) => {
    // console.log(store);
  });*/

    return { state }
  }


});
</script>

<style scoped>
pre {
  background-color: silver;
}
</style>