
import Country from '@/classes/Country';
import Money from '@/classes/Money';
import { UrlHelper } from '@/classes/UrlHelper';
import { defineComponent, computed, PropType } from 'vue';
import { Price } from '../classes/Models';

export default defineComponent({
  name: 'BuyButtonComponent',
  emits: ['buy'],
  props: {
    percentage: Number,
    isbn: String,
    country: String,
    prices: Array as PropType<Array<Price>>
  },
  setup(props, { emit }) {
    const totalSuppliers = computed(() => props.prices?.length);
    const updatingSuppliers = computed(() => props.prices?.filter(price => price.updating).length);
    const cheapestSupplier = computed(() => !props.prices ? null : props.prices.find(price => price.price > 0));
    const cheapestSupplierName = computed(() => cheapestSupplier.value?.supplier);
    const cheapestSupplierPrice = computed(() => Money.getTotalFormatted(cheapestSupplier.value));

    const averagePrice = computed(() => {
      if (props.prices?.length > 0) {
        const sum = props.prices.map(price => Money.getTotal(price)).reduce((sum, price) => sum += price);
        return sum / props.prices.filter(price => price.price > 0).length;
      }
    });
    const rrp = computed(() => Money.formatPrice(averagePrice.value));
    const savings = computed(() => {
      if (cheapestSupplier.value && averagePrice.value) {
        return Money.formatPrice(averagePrice.value - Money.getTotal(cheapestSupplier.value));
      }
    });
    const isUpdating = computed(() => updatingSuppliers.value > 0);
    const buyLink = computed(() => {
      return UrlHelper.getBuyUrl(props.country, cheapestSupplierName.value, props.isbn);
    });

    
    return { totalSuppliers, updatingSuppliers, cheapestSupplier, cheapestSupplierName, cheapestSupplierPrice, rrp, savings, isUpdating, buyLink }
  },
});
