
import { defineComponent, computed } from 'vue';
import { Price } from '../classes/Models';
import Money from '../classes/Money';

export default defineComponent({
  name: 'PriceTableRowComponent',
  components: {
  },
  props: {
    country: String,
    price: Price,
    visibility: Object,
    isbn: String
  },
  setup(props) {
    const bookPrice = computed(() => Money.formatPrice(props.price?.price, '?'));
    const postage = computed(() => Money.formatPrice(props.price?.postage, 'Free'));
    const total = computed(() => Money.getTotalFormatted(props.price, '?'));
    const delivery = computed(() => props.price.delivery);

    function buy(isbn: string, supplier: string) {
      return `${process.env.VUE_APP_LIBRARIST_API}${props.country}/buy/${supplier}/${isbn}`;
    }

    return { bookPrice, postage, total, delivery, buy }
  },
});
