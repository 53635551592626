import { Module } from 'vuex';
import { db } from "../db";
import axios from 'axios'
import { Book } from '@/classes/Models';
import { RootState } from './Store';

export interface BookState {
  books: Map<string, Book>;
  isbn: string;
}

const bookModule: Module<BookState, RootState> = {
  namespaced: true,
  state: {books: new Map()} as BookState,
  mutations: {
    clear(state) {
        state.books.clear();
    },
    setIsbn(state, isbn) {
      state.isbn = isbn;
    },
    set(state, books: Array<Book>) {
      books.forEach(book => state.books.set(book.isbn, book));
    },
    markBooksWhichWillUpdate(state, isbns: Array<string>) {
      isbns.forEach(isbn => { 
        if (state.books.has(isbn)) state.books.get(isbn).timestamp = null;
      });
    },
  },
  actions: {
    setBooksAndUpdatePrices: async({state, dispatch, commit}, {country, books}: {country: string; books: Array<Book>}) => {
      books = [].concat(books || []);
      const newBooks = books.filter(book => !state.books.has(book.isbn));
      commit("set", books);
      const booksNeedingUpdate = books.filter(book => book.needsUpdate()).map(book => book.isbn);
      if (booksNeedingUpdate.length > 0) {
        // console.log(books.filter(book => book.needsUpdate()));
        dispatch("fetchBooks", { country: country, isbns: booksNeedingUpdate });
      }

      dispatch("price/load", { country: country, isbns: newBooks.map(book => book.isbn ) }, { root: true });
    },
    fetchBooks: async ({commit}, {country, isbns}) => {
      isbns = [].concat(isbns || []);
      commit("markBooksWhichWillUpdate", isbns);

      await axios.post(
        process.env.VUE_APP_LIBRARIST_API + `task/books`,
        isbns.map((isbn: string) => ({ isbn: isbn, country: country}))
      );
    },

    load: async ({ dispatch }, { country, isbns }) => {
      isbns = [].concat(isbns || []);
      for (let i = 0; i < isbns.length; i += 10) {
        const collection = db.collection("books");

        let end = i + 10;
        if (end > isbns.length) end = isbns.length;
        const chunk: Set<string> = new Set(isbns.slice(i, end));

        let update = false;
        collection.where("isbn", "in", [...chunk]).onSnapshot((snapshot) => {
          if (snapshot.metadata.fromCache) {
            // console.log("skipping search snapshot from cache: " + snapshot.size);
            return;
          }
          let books: Array<Book> = [];
          snapshot.forEach(snap => {
            // console.log(snap.data());

            const book = Object.assign(new Book, snap.data());
            books.push(book);
            chunk.delete(book.isbn);
          });

          if (update) {
            // in case of update, propagate only changed books
            const changes: Set<string> = new Set();
            snapshot.docChanges().forEach(change => changes.add(change.doc.data().isbn));
            books = books.filter(book => changes.has(book.isbn));
          } else {
            // in case of first load, add missing books
            chunk.forEach(isbn => books.push(new Book(isbn)));
          }

          update = true;

          dispatch("setBooksAndUpdatePrices", { country: country, books: books });
        });
      }
    },

    loadShelf: async ({ dispatch, commit }, { country, shelf }) => {
      commit("clear");
      let update = false;
      db.collection("books").where("shelves", "array-contains", shelf).limit(35).onSnapshot((snapshot) => {
        let books: Array<Book> = [];

        snapshot.forEach(snap => {
          const book = Object.assign(new Book, snap.data());
          if (book.isbn) books.push(book);
        });

        // in case of update, propagate only changed books
        if (update) {
          const changes: Set<string> = new Set();
          snapshot.docChanges().forEach(change => changes.add(change.doc.data().isbn));
          books = books.filter(book => changes.has(book.isbn));
        }

        update = true;

        dispatch("setBooksAndUpdatePrices", { country: country, books: books });
      });
    },
    init: async ({ commit }, { country, isbn }) => {
      commit("updateContext", { country: country }, { root: true });
      commit("setIsbn", isbn );
    },
  },
}

export default bookModule;
