import URL from 'url-parse';
import Page from '@/classes/Page';
import Country from '@/classes/Country';

export class UrlParser {
    public page: Page;
    public country: Country;
    public isbn: string;
    public search: string;
    public uri: string;

    constructor() {
        const url = new URL(window.document.URL, true);
        this.uri = url.pathname + url.query;
        let arr;

        if (url.pathname === '/') {
            this.page = Page.REDIRECT;
        }

        if (!this.page) {
            arr = url.pathname.match("/([a-z]{2})/book/([0-9]{13})$");
            if (arr && arr.length == 3) {
                this.page = Page.BOOK;
                this.country = Country.valueOf(arr[1]);
                this.isbn = arr[2];
            }
        }

        if (!this.page) {
            arr = url.pathname.match("/([a-z]{2})/search");
            if (arr && arr.length == 2 && url.query !== undefined) {
                this.page = Page.SEARCH;
                this.country = Country.valueOf(arr[1]);
                this.search = url.query["q"];
            }
        }

        if (!this.page) {
            arr = url.pathname.match("/([a-z]{2})$");
            if (arr && arr.length == 2) {
                this.page = Page.HOME;
                this.country = Country.valueOf(arr[1]);
            }
        }

        if (!this.page) {
            arr = url.pathname.match("/([a-z]{2})[$|/.*]");
            if (arr && arr.length == 2) {
                this.page = Page.NOT_FOUND;
                this.country = Country.valueOf(arr[1]);
            }
        }
    }
}