
import { useStore } from '@/store/Store';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'SearchComponent',
  setup() {
    const store = useStore();
    const country = computed(() => store.state.country )
    const query = ref(computed(() => store.state.search.query ).value);
    
    return { query, country }
  },
});
