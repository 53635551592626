
import { defineComponent, computed, PropType } from 'vue';
import { Book, Price } from '../classes/Models';
import Money from '@/classes/Money';
import PriceButtonComponent from './PriceButtonComponent.vue'
import Enumerable from 'linq';

export default defineComponent({
  name: 'BookComponent',
  components: {
    priceButton: PriceButtonComponent
  },
  props: {
    book: Book,
    prices: Array as PropType<Array<Price>>,
    country: String,
    width: String
  },
  setup(props) {
    const title = computed(() => {
      if (props.book?.title) return props.book?.title;

      if (props.book && props.book.timestamp) return "No info available";
    });

    const authors = computed(() => props.book?.authors ? props.book?.authors[0] : '');
    const cover = computed(() => `https://cover.librarist.com/${props.book?.isbn}.jpg`)
    const link = computed(() => `/${props.country}/book/${props.book?.isbn}`)
    const updating = computed(() => Enumerable.from(props.prices).any(price => price.updating));
    const price = computed(() => {
      if (!props.prices || props.prices.length ==0 ) return 'N/A';
      const lowest = props.prices.reduce((previous, current) => {
        const previousPrice = Money.getTotal(previous);
        const currentPrice = Money.getTotal(current);
        
        if (!currentPrice) return previous;
        if (!previousPrice) return current;
        
        return previousPrice < currentPrice ? previous : current;
      });
      return Money.getTotalFormatted(lowest, "N/A");
    });

    return { title, authors, cover, link, price, updating }
  },
});
