import Country from '@/classes/Country';
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import bookModule, { BookState } from './BookModule';
import NotFoundModule, { NotFoundState } from './NotFoundModule';
import priceModule from './PriceModule';
import { PriceState } from './PriceModule';
import searchModule, { SearchState } from './SearchModule';

export interface RootState {
  price: PriceState;
  book: BookState;
  search: SearchState;
  notfound: NotFoundState;
  
  country: Country;
}

// define injection key
export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
  modules: {
    book: bookModule,
    price: priceModule,
    search: searchModule,
    notfound: NotFoundModule
  },
  state: {} as RootState,
  getters: {
  },
  mutations: {
    updateContext(state, { country }) {
      state.country = country;
    }
  },
  actions: {
    init: async ({ commit }, { country }) => {
      commit("updateContext", { country: country });
    },
  }
})

export function useStore() {
  return baseUseStore(key)
}