<template>
  <div class="buy-button text-center">
    <div class="pb-1">
      <a :href="buyLink" :class="{ disabled: !cheapestSupplierName }" target="_blank" :disabled="!cheapestSupplierName" class="btn btn-primary btn-lg" role="button" aria-disabled="true">
        <font-awesome-icon v-if="!isUpdating" icon="shopping-cart" />
        <font-awesome-icon v-if="isUpdating" icon="sync-alt" spin />

        <span v-if="totalSuppliers">
          <span v-if="!cheapestSupplierName && !isUpdating" class="na">Not Available</span>
          <span v-if="cheapestSupplierName" class="price">{{ cheapestSupplierPrice }}</span>
          <span v-if="cheapestSupplierName" class="supplier"> at <span class="text-decoration-underline">{{ cheapestSupplierName }}</span></span>
        </span>

        <span v-if="(!cheapestSupplierName && isUpdating) || !totalSuppliers" class="na">Updating prices</span>
      </a>
    </div>
    <div class="text-center">
      <div v-if="!isUpdating && !cheapestSupplierName && totalSuppliers" class="na">
        <span>we searched {{ totalSuppliers }} stores :(</span> 
      </div>
      <div v-if="!isUpdating && cheapestSupplierName" class="saving">
        <span class="text-decoration-line-through">{{ rrp }}</span> You save {{ savings }}
      </div>
      <div v-if="isUpdating" class="updating">
        Updating {{ totalSuppliers }} prices - {{ percentage }}% done
      </div>
      
    </div>
  </div>
</template>

<script lang="ts">
import Country from '@/classes/Country';
import Money from '@/classes/Money';
import { UrlHelper } from '@/classes/UrlHelper';
import { defineComponent, computed, PropType } from 'vue';
import { Price } from '../classes/Models';

export default defineComponent({
  name: 'BuyButtonComponent',
  emits: ['buy'],
  props: {
    percentage: Number,
    isbn: String,
    country: String,
    prices: Array as PropType<Array<Price>>
  },
  setup(props, { emit }) {
    const totalSuppliers = computed(() => props.prices?.length);
    const updatingSuppliers = computed(() => props.prices?.filter(price => price.updating).length);
    const cheapestSupplier = computed(() => !props.prices ? null : props.prices.find(price => price.price > 0));
    const cheapestSupplierName = computed(() => cheapestSupplier.value?.supplier);
    const cheapestSupplierPrice = computed(() => Money.getTotalFormatted(cheapestSupplier.value));

    const averagePrice = computed(() => {
      if (props.prices?.length > 0) {
        const sum = props.prices.map(price => Money.getTotal(price)).reduce((sum, price) => sum += price);
        return sum / props.prices.filter(price => price.price > 0).length;
      }
    });
    const rrp = computed(() => Money.formatPrice(averagePrice.value));
    const savings = computed(() => {
      if (cheapestSupplier.value && averagePrice.value) {
        return Money.formatPrice(averagePrice.value - Money.getTotal(cheapestSupplier.value));
      }
    });
    const isUpdating = computed(() => updatingSuppliers.value > 0);
    const buyLink = computed(() => {
      return UrlHelper.getBuyUrl(props.country, cheapestSupplierName.value, props.isbn);
    });

    
    return { totalSuppliers, updatingSuppliers, cheapestSupplier, cheapestSupplierName, cheapestSupplierPrice, rrp, savings, isUpdating, buyLink }
  },
});
</script>

<style lang="scss" scoped>
.supplier {
  white-space: nowrap;
}
.btn svg {
  margin-right: 0.5em;
}

</style>