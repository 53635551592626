import { store } from '@/store/Store'
import Enumerable from 'linq';
import { Price } from './Models';

class PriceUpdater {

  private interval: number;
  private inProgress: Set<string> = new Set();
  private static max = 3;

  public start() {
    if (this.interval != null) {
      // console.log('Price updater is already started');
      return;
    }
    this.interval = setInterval(() => {
      // console.log('tick');
      // console.log(store.state.price.prices);
      // console.log(store.state.price.prices);
      const outdated: Set<string> = new Set();
      store.state.price.prices.forEach((value, key) => {
         if (!value || value.length ==0 || value.some(price => price.isOutdated())) {
          // add to outdated collection 
          outdated.add(key);
         }
         
         if (value.every(price => !price.isOutdated() && !price.updating)) {
          // remove from in progress (if they were processing)
          this.inProgress.delete(key);
         }
      });      
    
      // console.log(`Found ${outdated.size} outdated prices` );

      if (outdated.size == 0) {
        // console.log('everythings up-to-date...');
        return;
      }

      if (this.inProgress.size > 2) {
        // console.log('We are already processing some prices updates - skipping...');
        return;
      }

      // console.log("1");
      // console.log(outdated);
      // const toUpdate = Enumerable.from(Array.from(outdated)).take(PriceUpdater.max-this.inProgress.size).select(k => k).toArray();
      const toUpdate = Enumerable.from(Array.from(outdated)).take(PriceUpdater.max).select(k => k).toArray();
      // console.log("2");
      // console.log(toUpdate);
      toUpdate.forEach(isbn => this.inProgress.add(isbn));
      store.dispatch("price/update", { country: store.state.country.code, isbns: toUpdate} )
    
    }, 2000);
  }
}

export const priceUpdater = new PriceUpdater();