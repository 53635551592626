<template>
  <div class="price-update-progress">
    <div class="progress" style="height: 2px;">
      <div class="progress-bar progress-bar-animated bg-dark" role="progressbar" :style="'width: ' + percentage + '%'" :aria-valuenow="percentage" aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PriceUpdateProgressComponent',
  props: {
    percentage: Number
  }
});
</script>

<style lang="scss" scoped>
</style>