import { Price } from './Models'
import { store } from '@/store/Store'

export default class Money {

    public static formatPrice(price: number, nullValue = '') {
        if (!price) return nullValue;
        const locale = store.state.country ? store.state.country.locale : "en-NZ";
        const currency = store.state.country ? store.state.country.currency : "NZD"; 
        return (price / 100).toLocaleString(locale, {style:"currency", currency: currency });
    }

    public static getTotal(price: Price): number {
        if (!price?.price) return null;
        
        let total = price.price;
        if (price.postage) total += price.postage;
        return total;
    }

    public static getTotalFormatted(price: Price, nullValue = ''): string {
        return this.formatPrice(this.getTotal(price), nullValue);
    }
}