<template>
  <tr class="Price">
    <td>{{ price.supplier }}</td>
    <td :class="visibility.availability">{{ delivery }}</td>
    <td :class="visibility.price"><div class="text-center">{{ bookPrice }}</div></td>
    <td :class="visibility.postage"><div class="text-center">{{ postage }}</div></td>
    <td>
      <div class="text-center">
        <a :href="buy(isbn, price.supplier)" target="_blank" class="btn btn-primary btn-sm" role="button" aria-disabled="true">
          <font-awesome-icon v-if="!price.updating" icon="shopping-cart" />
          <font-awesome-icon v-if="price.updating" icon="sync-alt" spin />
          {{ total }}
        </a>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Price } from '../classes/Models';
import Money from '../classes/Money';

export default defineComponent({
  name: 'PriceTableRowComponent',
  components: {
  },
  props: {
    country: String,
    price: Price,
    visibility: Object,
    isbn: String
  },
  setup(props) {
    const bookPrice = computed(() => Money.formatPrice(props.price?.price, '?'));
    const postage = computed(() => Money.formatPrice(props.price?.postage, 'Free'));
    const total = computed(() => Money.getTotalFormatted(props.price, '?'));
    const delivery = computed(() => props.price.delivery);

    function buy(isbn: string, supplier: string) {
      return `${process.env.VUE_APP_LIBRARIST_API}${props.country}/buy/${supplier}/${isbn}`;
    }

    return { bookPrice, postage, total, delivery, buy }
  },
});
</script>

<style lang="scss" scoped>
a.btn svg {
  margin-right: 0.5em;
}
td {
  vertical-align: middle;
}
</style>