<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark mb-4">
    <div class="container">

      <a class="navbar-brand" :href="home()">Librarist</a>

      <div class="navbar-expand" id="navbarNavAltMarkup">
        <div v-if="country" class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link flag dropdown-toggle text-white" href="" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ country.code.toUpperCase() }}   
                <span class="flag-icon" :class="`flag-icon-${country.flag}`"></span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li v-for="country in countries" :key="country.code">
                  <a class="dropdown-item flag" :href="link(country.code)"><span class="flag-icon" :class="`flag-icon-${country.flag}`"></span> {{ country.name }}</a>
                </li>
              </ul>
            </li>
        </div>
      </div>

      <div class="search ms-auto">
        <form v-if="showSearch" :action="`${home()}/search`" class="d-flex ms-auto">
          <input name="q" v-model="query" class="form-control" type="search" placeholder="Search" aria-label="Search">
        </form>
      </div>

      <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

    </div>
  </nav>
</template>

<script lang="ts">
import Country from '@/classes/Country';
import { useStore } from '@/store/Store';
import { defineComponent, computed, ref } from 'vue';
import { urlHelper } from '@/classes/UrlHelper';

export default defineComponent({
  name: 'NavbarComponent',
  props: {
    showSearch: Boolean
  },
  setup(props) {
    const store = useStore();
    const country = computed(() => store.state.country );
    const query = ref(computed(() => store.state.search.query || ""));
    const countries = computed(() => Array.from(Country.countries.values()).filter(c => c.code != country.value.code));

    function link(country: string): string {
      return urlHelper.getUrlForCountry(country);
    }

    function home(): string {
      return urlHelper.getHomeUrl();
    }

    return { query, country, countries, link, home }
  },
});
</script>

<style lang="scss" scoped>
.flag {
  font-size: 1.0em;
}

.flag .flag-icon {
  margin-left: 4px;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-nav .nav-link.flag {
  font-size: 1.25em;
  padding-left: 0.3rem;
}
</style>