<template>
  <div>
    <search></search>
    <shelf name="Bestsellers" :country="country.code" :books="books" :prices="prices"></shelf>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store/Store'
import SearchComponent from '@/components/SearchComponent.vue';
import ShelfComponent from '@/components/ShelfComponent.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    search: SearchComponent,
    shelf: ShelfComponent
  },
  setup() {
    const store = useStore();
    const books = computed(() => store.state.book.books);
    const prices = computed(() => store.state.price.prices);
    const country = computed(() => store.state.country);
    
    store.dispatch("book/loadShelf", {country: country.value.code, shelf: "Bestsellers"});

    return { screen, country, books, prices }
  }
});
</script>

<style scoped>

.shelf {
  display: flex;
}
.shelf .book {
  width: 4rem;
}
</style>