<template>
  <div>
    <h2>404 - Page Not Found</h2>
    <div>Sorry, we couldn't find what you were looking for...</div>
    <a href="/" class="btn btn-primary my-3">Go to Homepage</a>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useHead } from '@vueuse/head';
import Head from '../classes/Head';

export default defineComponent({
  name: 'BookView',
  components: {
  },
  setup() {
    useHead(computed(() => Head.notFound()));

    return {  }
  }
});
</script>

<style scoped>
</style>