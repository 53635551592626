import { Module } from 'vuex';
import { db } from "../db";
import axios from 'axios'
import { Price } from '@/classes/Models';
import { RootState } from './Store';
import { priceUpdater } from '@/classes/PriceUpdater';

export interface PriceState {
  prices: Map<string, Array<Price>>;
  priceUpdater: object;
}

const priceModule: Module<PriceState, RootState> = {
  namespaced: true,
  state: {prices: new Map()} as PriceState,
  mutations: {
    clear(state) {
      state.prices.clear();
    },
    set(state, prices: Map<string, Array<Price>>) {
      prices.forEach((value, key) => {
        state.prices.set(key, value);
      });
    }
  },
  actions: {
    update: async ({ state }, { country, isbns }) => {
      isbns = [].concat(isbns || []);
      await axios.post(
        // process.env.VUE_APP_LIBRARIST_API + `task/prices`,
        "https://us-central1-librarist.cloudfunctions.net/update-prices",
        isbns.map((isbn: string) => ({ isbn: isbn, country: country }))
      );
    },
    load: async ({ dispatch, commit }, { country, isbns }) => {
      priceUpdater.start();

      // ([...books] as Array<Book>).map(book => book.isbn)
      //const isbns: Array<string> = ([...books.slice(0, 5)] as Array<Book>).map(book => book.isbn);
      isbns = [].concat(isbns || []);
      // console.log(isbns);
      for (let i = 0; i < isbns.length; i += 10) {
        const collection = db.collection(`prices-${country}`);

        let end = i + 10;
        if (end > isbns.length) end = isbns.length;
        const chunk: Array<string> = isbns.slice(i, end);

        collection.where("isbn", "in", chunk).onSnapshot((snapshot) => {
          if (snapshot.metadata.fromCache) {
            // console.log("skipping price snapshot from cache: " + snapshot.size);
            // return;
          }
          const prices: Map<string, Array<Price>> = new Map();
          chunk.forEach((isbn: string) => prices.set(isbn, []));
          snapshot.forEach(snap => {
            const price = Object.assign(new Price, snap.data());
            prices.get(price.isbn).push(price);
          });

          commit('set', prices);
        });
      }
    },
  }
}

export default priceModule;