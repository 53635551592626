<template>
  <div class="Book">
    <div class="card text-center">
      <div class="cover" v:v-if="cover">
        <img  :src="cover" class="card-img-top img-fluid" alt="...">
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <h4 class="card-text">{{ authors }}</h4>
        <price-button :price="price" :updating="updating" :link="link"></price-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { Book, Price } from '../classes/Models';
import Money from '@/classes/Money';
import PriceButtonComponent from './PriceButtonComponent.vue'
import Enumerable from 'linq';

export default defineComponent({
  name: 'BookComponent',
  components: {
    priceButton: PriceButtonComponent
  },
  props: {
    book: Book,
    prices: Array as PropType<Array<Price>>,
    country: String,
    width: String
  },
  setup(props) {
    const title = computed(() => {
      if (props.book?.title) return props.book?.title;

      if (props.book && props.book.timestamp) return "No info available";
    });

    const authors = computed(() => props.book?.authors ? props.book?.authors[0] : '');
    const cover = computed(() => `https://cover.librarist.com/${props.book?.isbn}.jpg`)
    const link = computed(() => `/${props.country}/book/${props.book?.isbn}`)
    const updating = computed(() => Enumerable.from(props.prices).any(price => price.updating));
    const price = computed(() => {
      if (!props.prices || props.prices.length ==0 ) return 'N/A';
      const lowest = props.prices.reduce((previous, current) => {
        const previousPrice = Money.getTotal(previous);
        const currentPrice = Money.getTotal(current);
        
        if (!currentPrice) return previous;
        if (!previousPrice) return current;
        
        return previousPrice < currentPrice ? previous : current;
      });
      return Money.getTotalFormatted(lowest, "N/A");
    });

    return { title, authors, cover, link, price, updating }
  },
});
</script>

<style lang="scss" scoped>

h4, h5 {
  line-height: 1.25;
  font-style: normal;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden; 
}

h5 {
  font-weight: 400;
  height: 2.5em;
  -webkit-line-clamp: 2;
}

h4 {
  font-weight: 400;
  font-size: 13px;
  text-decoration: underline;
  height: 1.25em;
  -webkit-line-clamp: 2;
}

a {
  margin-bottom: 10px;
}
.card-body {
  padding: 10px 0px;
}

.card {
    border: none;
}

.cover {
  position: relative;
  overflow: hidden;
}

.cover:after {
  content: '';
  display: block;
  padding-top: 150%;
}

.cover img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
</style>