
import Country from '@/classes/Country';
import { useStore } from '@/store/Store';
import { defineComponent, computed, ref } from 'vue';
import { urlHelper } from '@/classes/UrlHelper';

export default defineComponent({
  name: 'NavbarComponent',
  props: {
    showSearch: Boolean
  },
  setup(props) {
    const store = useStore();
    const country = computed(() => store.state.country );
    const query = ref(computed(() => store.state.search.query || ""));
    const countries = computed(() => Array.from(Country.countries.values()).filter(c => c.code != country.value.code));

    function link(country: string): string {
      return urlHelper.getUrlForCountry(country);
    }

    function home(): string {
      return urlHelper.getHomeUrl();
    }

    return { query, country, countries, link, home }
  },
});
