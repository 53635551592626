import URL from 'url-parse';
import { store } from '@/store/Store'

export class UrlHelper {

  private url: URL;

  constructor() {
    this.url = new URL(window.document.URL, true);
  }

  public getHomeUrl() {
    return `/${store.state.country ? store.state.country.code : ''}`;
  }

  public getUrlForCountry(country: string) {
    const start = `${this.url.protocol}//${this.url.host}`;
    return this.url.href.replace(`${start}/${store.state.country.code}`, `/${country}`);
  }

  public static getBuyUrl(country: string, supplier: string, isbn: string): string {
    return `${process.env.VUE_APP_LIBRARIST_API}${country}/buy/${supplier}/${isbn}`;
  }
}

export const urlHelper = new UrlHelper();